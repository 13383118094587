const theme = {
    sidePanelBg: '#ffffff',//color del panel inferior
    titleTextColor: '#000000',//color sel titulo Layer 
    sidePanelHeaderBg: '#f7f7F7',//color del encabezado del panel
    subtextColorActive: '#44CC5A',//color de los iconos cuando se seleccionan
    tooltipBg: '#44CC5A',//color de los mensajes que salen cuando nos colocamos enciam de los iconos
    tooltipColor: '#ffffff',//color de la letra de los mensajes que salen cuando nos colocamos enciam de los iconos
    dropdownListBgd: '#ffffff',//color del desplegable cuando damos click en los capos Layer Blending y Map Overlay Blending
    textColorHl: '#23692F',//color de los iconos cuando pasamos por encima de ellos
    inputBgd: '#f7f7f7',//color del input de los campos Layer Blending y Map Overlay Blending
    inputBgdHover: '#ffffff',//color del input de los campos cuando pasas por encima Layer Blending y Map Overlay Blending
    inputBgdActive: '#ffffff',//color del input de los campos Layer Blending y Map Overlay Blending cuando se seleccionan
    dropdownListHighlightBg: '#f0f0f0',//color de las opciones de Layer Blending y Map Overlay cuando te paras encima
    panelBackground: '#f7f7F7',//color de los paneles de configuración en interactions y base map con mapstyle
    panelBackgroundHover: '#f7f7F7',//color de los paneles de configuración en base map con mapstyle cuando pasas por encima el cursor
    secondaryInputBgd: '#f7f7F7',//color del boton absolute esta dentro de tooltip en intercations
    secondaryInputBgdActive: '#f7f7F7',
    secondaryInputBgdHover: '#ffffff',
    panelActiveBg: '#f7f7F7',
    textColor: '#A0A7B4',//color de la letra en las opciones dentro de base map y interacctions
    primaryBtnBgd: '#44CC5A',//color de los botones
    primaryBtnColor: '#FFFFFF',
    primaryBtnActColor: '#FFFFFF',
    primaryBtnBgdHover: '#23692F',
    selectActiveBorderColor: '#FF5733',
    labelColor: '#44CC5A',//color de algunos iconos y letras pequeñas
    labelColorLT: '#44CC5A',//color de documentos dentro de add data
    subtextColor: '#44CC5A',//color del share
    switchTrackBgdActive: '#44CC5A',//color del switch en interactions
    secondaryBtnBgd: '#44CC5A',//boton add data
  };

  export default theme;