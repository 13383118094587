import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import keycloak from "./keycloak-config";

const ExportMap = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState("Default");
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.API_URL}/groups/getAll`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      .then(({ data }) => {
        const groups = data.value;
        setGroups(groups);
      })
      .catch((error) => {
        console.error("Error fetching groups", error);
      });
  }, []);

  const buttonStyle = {
    position: "absolute",
    zIndex: 100,
    top: 246,
    right: 12,
    padding: "0px",
    width: "32px",
    height: "32px",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    formData.append("group_uuid", group);

    try {
      onSubmit(formData);
      handleClose();
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="add a map"
        onClick={handleClickOpen}
        style={buttonStyle}
      >
        <SaveIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleFormSubmit,
        }}
      >
        <DialogTitle>Almacenar mapa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="normal"
            id="title"
            name="title"
            label="Titulo"
            type="text"
            fullWidth
            variant="outlined"
          ></TextField>

          <TextField
            autoFocus
            required
            margin="normal"
            id="description"
            name="description"
            label="Descripción"
            type="text"
            fullWidth
            variant="outlined"
            multiline
          ></TextField>

          <TextField
            margin="normal"
            id="select-group"
            select
            value={group}
            onChange={handleGroupChange}
            label="Grupos"
            helperText="Selecciona un grupo para el mapa"
          >
            {groups?.map((option) => (
              <MenuItem key={option.uuid} value={option.uuid}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Enviar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportMap;
